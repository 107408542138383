  <div class="achievements" fxLayout="column">    
    <app-employer [experience]="data"></app-employer>
    <hr class="title-divider"/>
    <div>ABOUT</div>
    <div class="about-row">{{data.about}}</div>
    <div>KEY ACHIEVEMENTS</div>
    <mat-list>
      <mat-list-item *ngFor="let item of data.achievements">{{item}}</mat-list-item>
    </mat-list>
  </div>

