import { Component, OnInit, Input } from '@angular/core';
import { Experience } from 'src/app/model/experience';
import { MatDialog } from '@angular/material/dialog';
import { ExperienceComponent } from '../experience/experience.component';

@Component({
  selector: 'app-employer',
  templateUrl: './employer.component.html',
  styleUrls: ['./employer.component.scss']
})
export class EmployerComponent implements OnInit {

  @Input()
  experience: Experience;

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }

  onClick() {
    console.log('hi');
    const dialogRef = this.dialog.open(ExperienceComponent, {
      width: '50vw',
      height: '80vh',
      data: this.experience
    });

  }

}
