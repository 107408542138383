import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FillChipComponent } from './fill-chip/fill-chip.component';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [FillChipComponent],
  exports: [FillChipComponent]
})
export class SharedModule { }
