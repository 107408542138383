import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResumeComponent } from './resume.component';
import { ExperienceComponent } from './experience/experience.component';
import { HeaderComponent } from './header/header.component';
import { SkillsComponent } from './skills/skills.component';
import { RecommendationComponent } from './recommendation/recommendation.component';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexLayoutModule } from '@angular/flex-layout';
import { EmployerComponent } from './employer/employer.component';
import { SharedModule } from '../shared/shared.module';
import { EducationComponent } from './education/education.component';

@NgModule({
  imports: [
    CommonModule,
    MatListModule,
    MatIconModule,
    MatTooltipModule,
    FlexLayoutModule,
    SharedModule,
    MatDialogModule
  ],
  declarations: [ResumeComponent, ExperienceComponent, HeaderComponent, SkillsComponent, RecommendationComponent, EmployerComponent, EducationComponent]
})
export class ResumeModule { }
