import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-fill-chip',
  templateUrl: './fill-chip.component.html',
  styleUrls: ['./fill-chip.component.scss']
})
export class FillChipComponent implements OnInit {

  @Input()
  description: string;

  @Input()
  percentFilled: number = 0;

  percentBlank: number = 100;

  percentFilledText: string;
  percentBlankText: string;

  width: string;

  fillColor: string;

  constructor() { }

  ngOnInit() {
    this.percentBlank = 100 - this.percentFilled;
    this.percentBlankText = this.percentBlank + "%";
    this.percentFilledText = this.percentFilled + "%";
    this.width = this.description.length*13 + "px";
    this.fillColor = this.percentFilled > 70 ? "#96D294":"#FF7D40";
  }

}
