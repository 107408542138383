<div fxLayout="row" style="min-height: 99vh;">
<div class="resume-container" fxLayout="column" fxLayoutAlign="center start">
  <div fxLayout="row" fxLayoutAlign="center center">
    <div fxFlex="85" fxFlex.xs="70">Brian Hadley is a full stack engineer with a passion for solving difficult technical problems and delivering innovative software solutions.</div>
    <div fxFlex="15" fxFlex.xs="30"><img src='/assets/images/headshot.jpeg' class='headshot'/></div>    
  </div>    
  <hr/>
  <div class='section-header'>EXPERIENCE</div>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around center" class="addl-row">    
    <app-employer *ngFor="let experience of experiences$ | async" [experience]="experience"></app-employer>
  </div>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutAlign.xs="center" class="addl-row">
    <div fxFlex="65" fxLayout="column"><app-skills></app-skills></div>
    <div fxFlex="35" fxLayout="column" ><div>EDUCATION</div>
      <div fxLayout="column" fxLayoutAlign="space-around center" style="height: 100%;">
        <app-education image="/assets/images/whitman.svg" description="BA in Philosophy" hoverText="ADD SOME HOVERTEXT"></app-education>
        <app-education image="/assets/images/nic.svg" description="AS in Computer Science" hoverText="ADD SOME HOVERTEXT"></app-education>
      </div>
    </div>
  </div>
  <hr/>
  <div fxLayout="row" fxLayoutAlign="end start" class='contact-row'>
    <mat-icon class="contact-icon">chat_bubble</mat-icon>
    <mat-icon class="contact-icon">mail_outline</mat-icon>
    <mat-icon class="contact-icon">phone_outline</mat-icon>    
    <img class="contact-icon" src="/assets/images/linkedin.png"/><a href="https://www.linkedin.com/in/brian-hadley-b071804/?lipi=urn%3Ali%3Apage%3Ad_flagship3_feed%3BbI%2Bj8l2zTYOM4j8IsFeSIw%3D%3D"></a>
    <img class="contact-icon" src="/assets/images/github.png"/>
    
  </div>
</div>
</div>
