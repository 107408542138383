import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Skill } from '../model/skill';

const skills: Skill[] = [{skillDescription: "C#", proficiency: 85},
                          {skillDescription: "Typescript", proficiency: 85},
                          {skillDescription: "SQL", proficiency: 95},
                          {skillDescription: "Angular", proficiency: 95},
                          {skillDescription: "Python", proficiency: 75},
                          {skillDescription: "Devops/CI/CD", proficiency: 60},
                          {skillDescription: "Azure", proficiency: 60},
                          {skillDescription: "SOLID Principles", proficiency: 80},
                          {skillDescription: "DDD", proficiency: 75},                          
                          {skillDescription: "AWS", proficiency: 40},
                          {skillDescription: "HTML/CSS", proficiency: 80},
                          {skillDescription: "EntityFramework", proficiency: 90},
                          {skillDescription: "Django", proficiency: 75},
                          {skillDescription: ".NET Core/Web API", proficiency: 80},
                          {skillDescription: "Tech Leadership", proficiency: 85},
                          {skillDescription: "RXJS", proficiency: 90}];

@Injectable({
  providedIn: 'root'
})
export class SkillService {

constructor() { }

getSkills(): Observable<Skill[]> {
  return of(skills.sort((a,b)=>b.proficiency - a.proficiency));
}

}
