import { Component, OnInit, Inject } from '@angular/core';
import { Experience } from 'src/app/model/experience';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({  
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss']
})
export class ExperienceComponent implements OnInit {

  

  constructor(
    public dialogRef: MatDialogRef<ExperienceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Experience) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

}
